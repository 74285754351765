.bulkUploadContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  position: relative;
  padding: 1rem 2rem;
}

.bulkUploadWrapper {
  margin-left: 1rem;
}

.downloadCSV {
  margin: 1rem 0;
  display: flex;
  height: 250px;
  flex-direction: column;
  align-items: center;
  border: 3px dashed #dfdede;
  border-radius: 5px;
  margin-bottom: 0;
}

.downloadCSV h4 {
  margin-top: 2rem;
}

.downloadCSV button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.downloadCSV input {
  position: absolute;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.downloadCSV button svg {
  font-size: 3.2rem;
  color: #3b3b3b;
}

.dropZondBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropZondBtnWrapper p {
  max-width: 80%;
  font-size: 0.8rem;
  background-color: var(--colorGray);
  padding: 0.5rem;
  border-radius: 0.3rem;
}
