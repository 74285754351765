* {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.row>th {
  /* width: 10px !important; */
  padding: 0px 26px !important;
}

#root {
  background-color: white !important;
  --gradientBg: radial-gradient(ellipse at center,
      rgba(255, 254, 234, 1) 0%,
      rgba(255, 254, 234, 1) 35%,
      #b7e8eb);
  --colorPrimary: #003cbf;
  --colorSecondary: #f31885;
  --colorAccent: #37b9f1;
  --colorGray: #f1f1f1;
  --colorDark: #1d2231;
  --colorText1: #303346;
  --colorText2: #565360;
  --colorLabel: #908e9b;
  --colorDisabled: #e1dfe9;
  --colorError: #ff4949;
}

.makeStyles-active-28 {
  color: #3396d3 !important;
}

.makeStyles-active-28 .makeStyles-icon-26 {
  color: #3396d3 !important;
}

.makeStyles-active-29 {
  color: #3396d3 !important;
}

.makeStyles-active-29 .makeStyles-icon-27 {
  color: #3396d3 !important;
}

.makeStyles-active-34 {
  color: #3396d3 !important;
}

.makeStyles-active-34 .makeStyles-icon-32 {
  color: #3396d3 !important;
}

.react-confirm-alert-body {
  z-index: 200 !important;
}

.react-confirm-alert-body>h1 {
  font-size: 20px !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

/* thead {
  background-color: rgb(255, 166, 0) !important;
} */
/* thead th {
  color: white !important;
} */
.Master__title {
  font-size: 30px;
  font-weight: 600;
}

.csvButton {
  background-color: #a0cb3a !important;
  color: white !important;
}

/* select {
  width: 317px !important;
  height: 42px !important;
  border: 1px solid #959595 !important;
  border-radius: 4px !important;
  background-color: white !important;
} */

.layoutPage {
  width: calc(100vw - 17rem);
  height: 100vh;
}

.Master__title {
  margin-top: 50px;
  font-weight: 600;
}

.MuiPaper-elevation1 {
  /* box-shadow: none !important; */
}

.MuiInput-underline::after {
  border-bottom: 2px solid #3396d3 !important;
}

.rc-anchor {
  width: 298px !important;
  height: 41px !important;
}

.flexContainer>* {
  margin: 8px 0 !important;
  margin-right: 15px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-animation-delay: 1s;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

@-webkit-keyframes autofill {

  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

.MuiPaginationItem-page {
  border-radius: 4px !important;
}

.MuiPaginationItem-page.Mui-selected {
  color: white !important;
  background-color: var(--colorPrimary) !important;
}

/* .sort {
  display: flex !important;
  align-items: center !important;
} */
/* Landscape phones and down */
@media (max-width: 480px) {
  .logo__right {
    display: none;
  }

  .cards__grid {
    margin: auto !important;
    width: 100% !important;
    place-items: center !important;
  }

  .threeButtons {
    flex-direction: column !important;
  }

  .threeButtons>button {
    width: 100% !important;
    margin: auto !important;
    margin-bottom: 10px !important;
    height: 40px;
  }

  .user_search {
    width: 100% !important;
    height: 37px !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .user_search_form {
    width: 100% !important;
    height: 37px !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  /* .dashboard__heading {
    margin: 20px 0 0 15px !important;
  } */
  .fullWidth {
    width: 100% !important;
    padding: 0 !important;
  }

  .flexContainer>* {
    margin: 8px 0 !important;
    margin-right: 0 !important;
  }
}

.commonPage {
  background-color: #fff;
  margin: 0 1rem;
  border-radius: 0.7rem;
  overflow: auto;
  height: auto;
}

.tableCell {
  font-size: 0.9rem !important;
  white-space: nowrap;
}

.cellEditBtn,
.cellDeleteBtn,
.cellViewBtn,
.cellLocationBtn {
  color: var(--colorError);
  font-size: 1.3rem !important;
  cursor: pointer;
}

.cellViewBtn {
  color: green;
  margin-right: 4px;
}

.cellLocationBtn {
  color: var(--colorSecondary);
  margin-right: 4px;
}

.cellEditBtn {
  color: var(--colorPrimary);
  margin-right: 4px;
}

.cellFilterIcon {
  margin-left: 5px;
}

.commonSearchWrapper {
  /* background-color: orange; */
  width: fit-content;
  padding: 1rem;
}

.tableHeadSearch {
  outline: none;
  border: 2px solid var(--colorGray);
  padding: 0.2rem;
  border-radius: 0.4rem;
  display: block;
  /* width: 100%; */
}

.tableHeadSearch::placeholder {
  color: #bebdc0;
  font-size: 0.7rem;
}

.commonLoader {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
}

.bulkUpdloadWrapper {
  min-height: calc(100vh - 30vh);
  padding: 1rem;
}

/* -------------------View-------------------- */
.viewWrapper {
  height: calc(100vh - 10vh);
  overflow-y: auto;
}

.viewWrapper>ul {
  list-style: none;
  /* background-color: orange; */
  width: 100%;
  box-shadow: 0px 0px 5px -2px #0000009d;
  border-radius: 0.2rem;
}

.viewWrapper>ul>li {
  padding: 0.8rem 2rem;
  margin-top: -1px;
  transition: 0.2s ease-in-out;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.viewWrapper>ul>li:nth-child(even) {
  background-color: var(--colorGray);
}

.viewWrapper>ul>li:hover {
  background-color: #c7c7c77c;
}

.viewWrapper .left {
  margin-right: 4rem;
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--colorPrimary);
}

.viewWrapper .right {
  font-size: 0.95rem;
}

.viewWrapper .viewTitle {
  margin: 0px 10px;
  padding: 10px 0;
}

/* -------------------View-------------------- */

.chipInSelect {
  margin: -0.5rem 0.2rem;
}

.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.hiddenFileInput {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.chipsContainer>div {
  padding: 5px 14px;
  position: relative;
  min-height: 45px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.filter {
  padding: 1rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}